<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div>
          <!-- 统计 -->
          <div class="data-statistics">
            <div class="item">
              <div class="br">
                <div class="title">账户余额</div>
                <div class="money"><span class="red">{{balanceInfo.balance}}</span>元</div>
              </div>
              <span class="btm" @click="showRecharge">马上充值</span>
              <!-- <span class="btm" @click="showTixian" style="padding-left: 10px">立即提现</span> -->
            </div>
            <div class="item">
              <div class="br">
                <div class="title">可提现余额</div>
                <div class="money"><span class="blue">{{balanceInfo.ok_money}}</span>元</div>
              </div>
              <span class="btm" @click="showTixian">立即提现</span>
            </div>
            <!-- <div class="item">
              <div class="br">
                <div class="title">提现中余额</div>
                <div class="money"><span>{{balanceInfo.tixian_money}}</span>元</div>
              </div>
            </div> -->
            <div class="item">
              <div class="br">
                <div class="title">推广收益</div>
                <div class="money"><span style="color: green;">{{balanceInfo.promotion_profit}}</span>元</div>
              </div>
              <span class="btm" @click="showTixian(), promotionProfit = true">立即提现</span>
            </div>
            <div class="item">
              <div class="four">
                <div class="title">总提现金额</div>
                <div class="money"><span>{{balanceInfo.total_money}}</span>元</div>
              </div>
            </div>
          </div>
          <!-- 充值Dialog -->
          <div class="rechargeDialog">
            <el-dialog
              title="余额充值"
              width="720px"
              :visible.sync="dialogVisible"
              @close="closePayDialog"
              >
              <div class="content">
                <div class="name">
                  充值账户：<span>{{accountzi}}</span>
                </div>
                <div class="price">
                  充值金额：
                  <div class="packing">
                    <div @click="selectAmount(index,item)" class="packing-item" :class="{'active': selectAmountIndex === index}" v-for="(item, index) in rechargeAmount" :key="index">
                      {{item}}元
                      <img class="dagou" src="~assets/icons/dagou.png">
                    </div>
                  </div>
                </div>
                <div class="input-num">
                  <div>
                    <el-input-number @mouseenter.native="controls = true" @mouseleave.native="controls = false" placeholder="请输入充值金额,最低1元" :controls="controls" width="300" ref="numInput" v-model="num" size="small" :precision="2" controls-position="right" @change="handleChange" :min="1"></el-input-number>
                  </div>
                  <span class="queding">确定</span>
                </div>
                <div>
                  <div class="payway-btn">
                    <span>支付方式：</span>
                    <div class="way">
                      <div class="payway" @click="selectAmount1(index,item.value)" :class="{'active': selectzhi === index}" v-for="(item, index) in rechargezhi" :key="index">
                        <img class="yue" src="~assets/icons/yue.png">
                        <img class="dagou" src="~assets/icons/dagou.png">
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                    <el-link type="success">推荐使用支付宝支付</el-link>
                  </div>
                </div>
                <div class="input-num">
                  <span @click="onlineConfirmRecharge" class="btn" style="margin-right: 16px;">在线充值</span>
                  <!-- <span @click="confirmRecharge" class="btn btns" >第三方充值充值</span> -->
                </div>
                <div style="color: red;">请您不要在微信中打开网站充值。微信不兼容！ 如出现错误，请您复制到您浏览器充值</div>
              </div>
            </el-dialog>
          </div>
          <!-- 提现Dialog -->
          <div class="tixianDialog">
            <el-dialog
              title="资金提现"
              width="560px"
              :visible.sync="tixianDialogVisible"
              @close="closeTixianDialog"
              >
              <div class="content">
                <div class="input-item">
                  <span>到帐账号：</span>
                  <div class="zhxg">
                    <span v-if="alipay">{{'微信' + hideCenterPhone(alipay) + '（' + hideStartName(trueName) + '）'}}</span>
                    <span @click="clickSet">点击修改</span>
                  </div>
                </div>
                <div class="input-item number">
                  <span>提现金额：</span>
                  <el-input-number v-model="tixianNum" @mouseenter.native="tixianControls = true" @mouseleave.native="tixianControls = false" :precision="2" controls-position="right" :controls="tixianControls" :min="1"></el-input-number>
                </div>
                <div class="btn-item">
                  <el-button v-if="promotionProfit" @click="promotionWithdrawal" type="primary">立即提现</el-button>
                  <el-button v-else @click="withdrawal" type="primary">立即提现</el-button>
                </div>
              </div>
            </el-dialog>
          </div>
          <!-- 表格 -->
          <div class="table">
            <div class="table-menu">
              <!-- <div> -->
              <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
                <el-menu-item index="0">全部</el-menu-item>
                <el-menu-item index="1">充值</el-menu-item>
                <el-menu-item index="2">消费</el-menu-item>
<!--                <el-menu-item index="3">收入</el-menu-item>-->
<!--                <el-menu-item index="4">提现</el-menu-item>-->
                <el-menu-item index="5">管理员转账</el-menu-item>
                <el-menu-item index="6">退款</el-menu-item>
              </el-menu>
              <div class="input-btn">
                <el-input class="el-input" v-model="id" placeholder="查询ID"></el-input>
                <el-button @click="clickQuery" type="primary" size="small">查询</el-button>
                <el-button @click="clickReset" size="small">重置</el-button>
                <el-button @click="exportPack" type="primary" size="small">导出</el-button>
              </div>
            </div>
            <!-- 内容 -->
            <div class="table-main">
              <el-table :data="moneyObj.data" class="el-table-main" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}">
                <el-table-column label="序号">
                  <template slot-scope="scope"><span>{{scope.$index+(moneyObj.current_page - 1) * moneyObj.per_page + 1}}</span></template>
                </el-table-column>
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="order_no" label="流水号"></el-table-column>
                <el-table-column prop="money" label="金额"></el-table-column>
                <el-table-column prop="after" label="当前余额"></el-table-column>
                <el-table-column prop="reason" label="类型"></el-table-column>
                <el-table-column prop="memo" label="描述"></el-table-column>
                <el-table-column label="创建时间">
                  <template slot-scope="scope">{{timeConversion(scope.row.createtime)}}</template>
                </el-table-column>
              </el-table>
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="currentChange"
                  :page-size="moneyObj.per_page"
                  layout="prev, pager, next"
                  :current-page="moneyObj.current_page"
                  :total="moneyObj.total">
                </el-pagination>
              </div>
            </div>
          </div>
          <!-- 是否实名认证弹窗 -->
          <el-dialog
            title="提示"
            :visible.sync="rechargeVisibleRecharge"
            width="30%">
            <span>实名认证或绑定店铺发货，可解锁低额金额充值！！！！</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="rechargeVisibleRecharge = false">取 消</el-button>
              <el-button type="primary" @click="toBandStore()">去绑定店铺</el-button>
              <el-button type="primary" @click="toBandIdCard()">去实名认证</el-button>
            </span>
          </el-dialog>
           <!-- 实名认证 -->
          <el-dialog
            title="提示"
            :visible.sync="idCardDialogVisible"
            width="30%">
            <p style="color: red;">
              根据国家 《快递暂行条例》规定，经营快递业务的企业收寄快件，应当对寄件人身份进行实名认证，认证信息只做核验，不进行任何保存。凡未核验的企业，网站将进行关停，整顿。为了网站的稳定运行请您进行身份核验
            </p>
            <el-form :model="cardShowForm" label-width="100px" :rules="cardShowForm" ref="aipayForm">
              <el-form-item label="真实姓名:" prop="aiPayName">
                <el-input v-model="cardShowForm.name" placeholder="真实姓名"></el-input>
              </el-form-item>
              <el-form-item label="身份证号:" prop="idCard">
                <el-input v-model="cardShowForm.idCard" placeholder="请输入身份证号"></el-input>
              </el-form-item>
              <el-form-item label="手机号:" prop="phone">
                <el-input v-model="cardShowForm.phone" placeholder="请输入手机号"></el-input>
                <small style="color: red">请您确保手机号实名信息与您填写的身份信息一致</small>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="idCardDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="idCardUpDate">确认提交</el-button>
            </span>
          </el-dialog>
          <!-- iframe -->
          <el-dialog
            title="微信充值"
            :visible.sync="wxRechargeDialogVisible"
            width="50%">
            <div class="codeWrap">
              <div class="codeWrapLeft">
                <div style="margin-bottom: 20px">
                  <p>充值编号: <span>{{ orderId }}</span></p>
                  <p>充值金额: <strong style="color: red">¥{{ num }}</strong> 元</p>
                </div>
                <div id="qrcode" ref="qrCodeDiv"></div>
                <div class="scanpay-tips">
                  <p>请使用微信扫一扫<br>扫描二维码支付</p>
                </div>
              </div>
              <div class="col-sm-6 hidden-xs">
                <div class="scanpay-screenshot">
                  <img src="../../../assets/screenshot-wechat.png" class="img-responsive" alt=""/>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="wxRechargeDialogVisible = false">取 消</el-button>
            </span>
          </el-dialog>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { topuprice, moneyInfo, moneylist, getUserMoney, balanceApply, profitlist, moneyDetailExport, idCardPhoneApi, paySubmitWeiXinPc, getOrderSuccess, applyromotionprofit } from 'network/api'
import { timeConversion, hideCenterPhone, hideStartName } from 'utils/utils'
import QRCode from 'qrcodejs2'
import store from '../../../store'
// import {topup} from "../../../network/api";

export default {
  components: {
    AppMain
  },
  inject: ['mainReload'],
  data () {
    return {
      activeIndex: '0',
      dialogVisible: false,
      tixianDialogVisible: false,
      rechargeAmount: ['20', '50', '100', '500'],
      rechargezhi: [{
        name: '支付宝支付',
        value: 'alipay'
      },
      // {
      //   name: '微信支付',
      //   value: 'wechat'
      // },
      {
        name: '微信支付',
        value: 'hfpay'
      }
      ],
      amountType: 'alipay',
      selectAmountIndex: 1,
      selectzhi: 0,
      num: undefined,
      tixianNum: 10,
      controls: false,
      tixianControls: false,
      balanceInfo: {},
      moneyObj: {},
      alipay: '',
      accountzi: '',
      trueName: '',
      currentPage: 1,
      moneylogId: '',
      reasonVal: '',
      id: '',
      rechargeVisibleRecharge: false,
      idCardDialogVisible: false,
      cardShowForm: {
        name: '',
        idCard: '',
        phone: this.$store.getters.getUserInfo.username
      },
      wxRechargeDialogVisible: false,
      orderId: '',
      timer: null,
      promotionProfit: false
    }
  },
  computed: {
    timeConversion () {
      return timeConversion
    },
    hideCenterPhone () {
      return hideCenterPhone
    },
    hideStartName () {
      return hideStartName
    }
  },
  watch: {
    wxRechargeDialogVisible: function (newVal, oldVal) {
      if (newVal === false) {
        this.clearTimer()
      }
    }
  },
  created () {
    if (this.selectAmountIndex === 1) {
      this.num = 50
    }
    // 获取余额信息
    moneyInfo().then(res => {
      this.balanceInfo = res.data
    })
    // 获取金钱列表
    this.getMoneylist()
    // 获取支付宝账号
    getUserMoney().then(res => {
      // console.log(res.data)
      this.accountzi = res.data.username
      this.alipay = res.data.ali_pay
      this.trueName = res.data.true_name
    })
  },
  destroyed () {
    this.clearTimer()
  },
  methods: {
    // 马上充值
    showRecharge () {
      this.dialogVisible = true
    },
    // 选择充值金额点击
    selectAmount (index, item) {
      this.selectAmountIndex = index
      this.num = item
    },
    // 充值方式
    selectAmount1 (index, value) {
      this.selectzhi = index
      this.amountType = value
    },
    // 关闭Dialog回调
    closePayDialog () {
    },
    // 计数器值改变
    handleChange (val) {
    },
    // 确认充值
    confirmRecharge () {
      if (this.num) {
        topuprice(this.num.toString(), this.amountType).then(res => {
          window.open(res.data.payUrl, '_blank')
        })
      } else {
        this.$message({
          message: '请输入金额',
          type: 'warning'
        })
      }
    },
    // 在线充值确认
    onlineConfirmRecharge () {
      const that = this
      const h = this.$createElement
      if (this.balanceInfo.balance > 500) {
        this.$msgbox({
          title: '请联系客服充值',
          message: h('img', { attrs: { src: that.$store.getters.getSiteInfo.wechat_service, style: 'width: 300px' } }),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
        return
      }
      if (this.num) {
        if (this.num > 500) {
          this.$msgbox({
            title: '请联系客服充值',
            message: h('img', { attrs: { src: that.$store.getters.getSiteInfo.wechat_service, style: 'width: 300px' } }),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          })
        } else {
          this.onLineRechargeTrue()
        }
      } else {
        this.$message({
          message: '请输入金额',
          type: 'warning'
        })
      }
    },
    onLineRechargeTrue () {
      var ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('micromessenger') !== -1) {
        this.$message({
          message: '请您不要在微信中打开网站充值，请您复制网址到您浏览器充值',
          type: 'warning'
        })
      } else {
        var returnurl = ''
        returnurl = 'http://' + window.location.host + '/account/balance'
        var token = store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
        if (this.amountType === 'alipay') {
          window.location.href = this.$url + '/api/api/paySubmitPc?money=' + this.num.toString() + '&paytype=' + this.amountType + '&returnurl=' + returnurl + '&tokenKey=' + token
        } else {
          paySubmitWeiXinPc({
            money: this.num.toString(),
            paytype: this.amountType
          }).then(res => {
            if (res.code !== 0) {
              this.orderId = res.data.orderid
              this.dialogVisible = false
              this.wxRechargeDialogVisible = true
              // .match(/weixin:\/\/[^']*/)[0]
              setTimeout(() => {
                this.bindQRCode(res.data.url)
              }, 500)
              this.rechargeSuccess(res.data.orderid)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      }
    },
    bindQRCode (url) {
      if (document.getElementById('qrcode')) {
        document.getElementById('qrcode').innerHTML = ''
      }
      /* eslint-disable */
      new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: '#000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.L
      })
    },
    rechargeSuccess (rechargeLogId) {
      if(!rechargeLogId) return
        this.timer = setInterval(() => {
          getOrderSuccess({ orderid: rechargeLogId }).then(res => {
            if (res.code !== 0) {
              clearInterval(this.timer)
              this.wxRechargeDialogVisible = false
              this.getMoneyNum()
              if (res.code == 1) {
                this.$message.success('充值成功')
                clearInterval(this.timer)
                this.getMoneylist()
              } else {
                this.$message.error('充值失败')
                clearInterval(this.timer)
              }
            }
          })
        }, 300)
    },
    clearTimer(){
      if (this.timer !== null) {
        clearInterval(this.timer)
      }
    },
    getMoneyNum () {
      moneyInfo().then(res => {
        this.balanceInfo = res.data
      })
    },
    toBandIdCard () {
      this.rechargeVisibleRecharge = false
      this.idCardDialogVisible = true
    },
    idCardUpDate () {
      if (!this.cardShowForm.idCard) {
        this.$message.warning('身份证号不能为空')
        return
      }
      if (!this.cardShowForm.name) {
        this.$message.warning('真实姓名不能为空')
        return
      }
      if (!this.cardShowForm.phone) {
        this.$message.warning('手机号不能为空')
        return
      }
      idCardPhoneApi(this.cardShowForm).then(res => {
        if (res.code === 1) {
          this.$message.success('认证成功')
          this.idCardDialogVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toBandStore () {
      this.$router.push('/orderAdmin/storeBind')
    },
    // 关闭提现Dialog回调
    closeTixianDialog () {
    },
    // 提现点击
    showTixian () {
      this.tixianDialogVisible = true
    },
    // 点击修改
    clickSet () {
      this.$router.push('/account/base')
    },
    // 获取金钱变动列表
    getMoneylist () {
      moneylist({
        reason: this.reasonVal,
        moneylog_id: this.moneylogId,
        page: this.currentPage
      }).then(res => {
        this.moneyObj = res.data
      })
    },
    // 获取收益变动列表
    getProfitlist () {
      profitlist({
        reason: this.reasonVal,
        moneylog_id: this.moneylogId,
        page: this.currentPage
      }).then(res => {
        this.moneyObj = res.data
      })
    },
    currentChange (val) {
      this.currentPage = val
      this.getMoneylist()
    },
    selectChange (index) {
      this.currentPage = 1
      switch (index) {
        case '0':
          this.reasonVal = ''
          this.getMoneylist()
          break
        case '1':
          this.reasonVal = '充值'
          this.getMoneylist()
          break
        case '2':
          this.reasonVal = '下单支付'
          this.getMoneylist()
          break
        case '3':
          this.reasonVal = '邀请奖励'
          this.getMoneylist()
          break
        case '4':
          this.reasonVal = '提现'
          this.getMoneylist()
          break
        case '5':
          this.reasonVal = '管理员转账'
          this.getMoneylist()
          break
        case '6':
          this.reasonVal = '退款'
          this.getMoneylist()
          break
      }
    },
    exportPack () {
      moneyDetailExport({
        reason: this.reasonVal,
        moneylog_id: this.moneylogId,
        page: this.currentPage
      }).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.$message.warning(res.msg)
        } else {
          this.download(res)
        }
      })
    },
    download (data) {
      if (!data) {
        return
      }
      const BLOB = new Blob([`\ufeff${data}`], { type: 'text/plain;charset=utf-8' })
      const url = window.URL.createObjectURL(BLOB)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '资金明细' + timeConversion() + '.csv')
      document.body.appendChild(link)
      link.click()
    },
    // 查询
    clickQuery () {
      this.moneylogId = this.id
      this.getMoneylist()
    },
    // 重置
    clickReset () {
      this.mainReload()
    },
    // 余额提现
    withdrawal () {
      if (this.tixianNum > this.balanceInfo.ok_money) {
        this.$message({
          message: '可提现余额不足',
          type: 'warning'
        })
        return false
      }
      balanceApply({
        money: this.tixianNum,
        account: this.alipay,
        name: this.trueName
      }).then(res => {
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.mainReload()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    promotionWithdrawal () {
      const data = {
        money: this.tixianNum,
        account: this.alipay,
        name: this.trueName
      }
      console.log(data)
      applyromotionprofit(data).then(res => {
        if (res.code === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.mainReload()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style scoped src="styles/views/account/balance.css">
</style>

<style>
.rechargeDialog .el-dialog__header, .tixianDialog .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}

.input-num .el-input-number--small {
  width: 180px;
}

.input-item.number .el-input-number {
  width: 324px;
}
</style>
